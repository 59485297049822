import { ErrorPage } from 'modules/errorPage';
import { useTranslations } from 'next-intl';

export const NotFoundPage = () => {
  const t = useTranslations();

  return (
    <ErrorPage
      t={t}
      imageAlt="sad girl"
      imageSrc="/images/sadGirl.webp"
      errorCode="404"
      textAlign="center"
    />
  );
};
