// Api
import { NotFoundPage } from 'modules/404';
import { QueryClient } from 'react-query';
import { dehydrate } from 'react-query/hydration';

export default NotFoundPage;

export async function getStaticProps({ locale }) {
  const queryClient = new QueryClient();

  return {
    props: {
      queryDehydratedState: dehydrate(queryClient),
      locale: locale,
      messages: require(`languages/${locale}/pages/404.js`).NotFound,
    },
    revalidate: 84600,
  };
}
